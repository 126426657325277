
import { useStoryblokBridge, useStoryblokApi } from '@storyblok/nuxt'
import {
  getAssetUrl,
  getLocaleConfigByPath,
} from './../../utils/storyblok-helper'
import { getSanitizedFullSlug } from './../../utils/url-helper'
import { domain } from './../../app.config'

const isExcludedStorySlug = (slug) => {
  const exclude = [/^([a-z]{2}-[a-z]{2})\/data/]
  return exclude.some((regex) => new RegExp(regex).test(slug) === true)
}

export default {
  asyncData({ app, params, error, env, payload, isStatic }) {
    const version = env.CONTENT_VERSION
    const cacheVersion = env.CONTENT_CACHE_VERSION
    const story = params.pathMatch

    if (isExcludedStorySlug(story)) {
      // exit with 404 as our data only routes have no visual part
      error({
        statusCode: 404,
        message: 'The requested page does not exist.',
      })
    }

    const localeConfig = getLocaleConfigByPath(params.pathMatch)

    return app.$storyapi
      .get(`cdn/stories/${story}`, {
        version,
        resolve_links: 'url',
        resolve_relations: 'cmp-global-reference.slot',
        cv: cacheVersion,
      })
      .then(async (res) => {
        let settings = null
        let notifications = []

        if (localeConfig) {
          if (payload?.settings === undefined) {
            // fetch settings, this will be also done in generate function, this part here is only for dev/ssr where generate will not be invoked
            const settingsSlug = localeConfig.slug + '/data/settings'
            settings = await app.$storyapi
              .get(`cdn/stories/${settingsSlug}`, {
                version,
                cv: cacheVersion,
              })
              .then((res) => res.data.story)
              .catch((res) => {
                error({
                  statusCode: res.response.status,
                  message: res.response.data,
                })
              })
          } else {
            settings = payload.settings
          }

          if (payload?.notifications === undefined) {
            const notificationsSlug = localeConfig.slug + '/data/notifications/'

            notifications = await app.$storyapi
              .get(`cdn/stories`, {
                version,
                cv: cacheVersion,
                starts_with: notificationsSlug,
                sort_by: 'position:asc',
              })
              .then((res) => {
                return res.data.stories.map((story) => story)
              })
              .catch((res) => {
                return []
              })
          } else {
            notifications = payload.notifications
          }

          // resolve category of blog article
          /*
          if (payload === undefined || payload.blogCategories === undefined) {
            console.log('refech')
          } else {
            console.log('already fetched')
          }
          */

          // resolve relations
          if (res.data.story.content.component === 'blog-article') {
            // for categories
            if (
              Array.isArray(res.data.story.content.category) &&
              res.data.story.content.category.length
            ) {
              const categoryUUID = res.data.story.content.category[0]

              if (payload?.blogCategories === undefined) {
                const category = await app.$storyapi
                  .get(`cdn/stories/${categoryUUID}`, {
                    version,
                    cv: cacheVersion,
                    resolve_links: 'url',
                    find_by: 'uuid',
                  })
                  .then((res) => {
                    return res.data.story
                  })
                  .catch(() => {
                    return categoryUUID
                  })

                res.data.story.content.category = [category]
              } else {
                const found = payload.blogCategories.find(
                  (d) => d.uuid === categoryUUID
                )
                res.data.story.content.category = found ? [found] : categoryUUID
              }
            }
            // for author
            if (res.data.story.content.author) {
              const authorUUID = res.data.story.content.author

              if (payload?.authors === undefined) {
                const author = await app.$storyapi
                  .get(`cdn/stories/${authorUUID}`, {
                    version,
                    cv: cacheVersion,
                    resolve_links: 'url',
                    find_by: 'uuid',
                  })
                  .then((res) => {
                    return res.data.story
                  })
                  .catch(() => {
                    return authorUUID
                  })

                res.data.story.content.author = author
              } else {
                res.data.story.content.author =
                  payload.authors.find((d) => d.uuid === authorUUID) ||
                  authorUUID
              }
            }
          }
        }

        // if its not localized, settings, notifications and locale is undefined
        return {
          story: res.data.story,
          settings,
          notifications,
          localeConfig,
          isStatic,
          blogCategories: payload?.blogCategories || [],
        }
      })
      .catch((res) => {
        if (!res.response) {
          error({
            statusCode: 404,
            message: 'The requested page does not exist.',
          })
        } else {
          error({
            statusCode: res.response.status,
            message: res.response.data,
          })
        }
      })
  },
  data() {
    return {
      story: null,
      settings: null,
      notifications: [],
      localeConfig: null,
      isStatic: true,
      blogCategories: [],
    }
  },
  head() {
    const meta = []
    const script = []

    // seo
    const title = this.story.content.meta_title || ''
    const description = this.story.content.meta_description || ''

    // og
    const ogTitle = this.story.content.meta_og_title || title
    const ogDescription = this.story.content.meta_og_description || description
    const ogImageFilename = this.story.content.meta_og_image?.filename || ''
    const ogImageSmart = this.story.content.meta_og_image_smart || false

    // twitter will not fallback to opengraph values because twitter will use opengraph tags for title, description and image as fallback
    const twitterSite = this.settings.twitter_site || ''
    const twitterTitle = this.story.content.meta_twitter_title || ''
    const twitterDescription = this.story.content.meta_twitter_description || ''
    const twitterImageFilename =
      this.story.content.meta_twitter_image?.filename || ''
    const twitterImageSmart =
      this.story.content.meta_twitter_image_smart || false

    const getAlternatesStories = (story) => {
      let alternates = []

      if (Array.isArray(story.alternates)) {
        alternates = story.alternates
          .map((alt) => {
            const localeConfig = getLocaleConfigByPath(alt.full_slug)

            if (localeConfig) {
              return {
                localeConfig,
                full_slug: getSanitizedFullSlug(alt.full_slug),
              }
            } else {
              return undefined
            }
          })
          .filter((alt) => alt !== undefined)
      }

      return alternates
    }

    // assign
    if (description) {
      meta.push({
        name: 'description',
        content: description,
      })
    }

    // og
    if (this.settings.site_name) {
      meta.push({
        property: 'og:site_name',
        content: this.settings.site_name,
      })
    }

    meta.push({
      property: 'og:locale',
      content: this.localeConfig.alternateCode,
    })

    const alternateStories = getAlternatesStories(this.story)

    alternateStories.forEach((s) => {
      meta.push({
        property: 'og:locale:alternate',
        content: s.localeConfig.alternateCode,
      })
    })

    meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: 'website',
    })

    const canonicalUrl = domain + getSanitizedFullSlug(this.story.full_slug)

    meta.push({
      property: 'og:url',
      content: canonicalUrl,
    })

    if (ogTitle) {
      meta.push({
        property: 'og:title',
        content: ogTitle,
      })
    }

    if (ogDescription) {
      meta.push({
        property: 'og:description',
        content: ogDescription,
      })
    }

    if (ogImageFilename) {
      meta.push({
        property: 'og:image',
        content: getAssetUrl(
          ogImageFilename,
          '1200x630' + (ogImageSmart ? '/smart' : '')
        ),
      })
      meta.push({
        property: 'og:image:width',
        content: '1200',
      })
      meta.push({
        property: 'og:image:height',
        content: '630',
      })
    }

    // twitter
    if (twitterSite) {
      meta.push({
        name: 'twitter:site',
        content: twitterSite,
      })
    }

    meta.push({
      name: 'twitter:card',
      content: 'summary',
    })

    if (twitterTitle) {
      meta.push({
        name: 'twitter:title',
        content: twitterTitle,
      })
    }

    if (twitterDescription) {
      meta.push({
        name: 'twitter:description',
        content: twitterDescription,
      })
    }

    if (twitterImageFilename) {
      meta.push({
        name: 'og:image',
        content: getAssetUrl(
          ogImageFilename,
          '1200x630' + (twitterImageSmart ? '/smart' : '')
        ),
      })
    }

    const robotsMetaValues = []

    if (this.story.content.search_engine_noindexing) {
      robotsMetaValues.push('noindex')
    }

    if (this.story.content.search_engine_nofollow) {
      robotsMetaValues.push('nofollow')
    }

    if (robotsMetaValues.length) {
      meta.push({ name: 'robots', content: robotsMetaValues.join(',') })
    }

    const htmlAttrs = {
      lang: this.localeConfig.code,
    }

    const link = []

    link.push({
      rel: 'canonical',
      href: canonicalUrl,
    })

    link.push({
      rel: 'dns-prefetch',
      href: '//app.usercentrics.eu',
    })

    link.push({
      rel: 'dns-prefetch',
      href: '//api.usercentrics.eu',
    })

    alternateStories.forEach((s) => {
      link.push({
        rel: 'alternate',
        hreflang: s.localeConfig.code,
        href: domain + s.full_slug,
      })
    })

    return {
      htmlAttrs,
      title,
      script,
      meta,
      link,
    }
  },
  mounted() {
    if (this.isStatic === false) {
      // TODO need that any more with new nuxt client?
      if (this.story.id)
        useStoryblokBridge(this.story.id, async (evStory) => {
          this.story = await this.getResolvedStoryContent(evStory)
        })
    }
  },
  methods: {
    // the storyblok bride will never deliver already resolved uuids, so we need to do this by ourself.
    // (remember: in asyncData, we can do this by the resolve_relations of the API)
    async getResolvedStoryContent(content) {
      if (content.slot !== undefined) {
        if (Array.isArray(content.slot)) {
          content.slot = await Promise.all(
            content.slot.map(
              async (cmp) => await this.getResolvedStoryContent(cmp)
            )
          )
        } else if (typeof content.slot === 'string') {
          const storyblokApi = useStoryblokApi()
          // uuid needs to be resolved by API
          const resolvedStory = await storyblokApi
            .get(`cdn/stories/${content.slot}`, {
              version: process.env.CONTENT_VERSION,
              cv: process.env.CONTENT_CACHE_VERSION,
              resolve_links: 'url',
              find_by: 'uuid',
            })
            .then((res) => {
              return res.data.story
            })
            .catch((res) => {
              console.log('error', res)
            })
          content.slot = await this.getResolvedStoryContent(resolvedStory)
        } else {
          content.slot = await this.getResolvedStoryContent(content.slot)
        }
      }

      return content
    },
    renderComponent(h, cmp, slot = null, rootStory = null) {
      let children = []

      // resolving global references
      // How it works?
      // 1. go to folder "global" with content type "global"
      // 2. create new entry inside of this folder (also "global" as content type)
      // 3. name this entry e.g. "Header" (slug=header)
      // 4. click on "block" and select the component you want to have globally
      // 5. go to the content entry where you want to place the global reference
      // 6. add a new block, and select "global-reference", select the story
      if (cmp.component === 'cmp-global-reference') {
        if (Array.isArray(cmp.slot.content)) {
          return this.renderComponents(h, cmp.slot.content, null, rootStory)
        } else {
          return this.renderComponents(h, [cmp.slot.content], null, rootStory)
        }
      } else if (
        cmp.component === 'global' &&
        cmp.slot &&
        cmp.slot.length === 1
      ) {
        // this will skip to render the "global" component, and will directly render the slot component instead
        cmp = cmp.slot[0]
      }

      Object.keys(cmp).forEach((attr) => {
        // this will handle default slots and named slots, like "slot_header" where the slot name is "header"
        if (attr.indexOf('slot') === 0) {
          const splittedAttr = attr.split('_')
          if (splittedAttr.length === 2) {
            children = children.concat(
              this.renderComponents(h, cmp[attr], splittedAttr[1], rootStory)
            )
          } else {
            children = children.concat(
              this.renderComponents(h, cmp[attr], null, rootStory)
            )
          }
        }
      })

      const componentProps = {
        key: cmp._uid,
        props: {
          blok: cmp,
          rootStory,
          settings: this.settings,
          notifications: this.notifications,
          localeConfig: this.localeConfig,
          blogCategories: this.blogCategories,
        },
      }

      if (!this.isStatic) {
        componentProps.directives = [
          {
            name: 'editable',
            value: cmp,
          },
        ]
      }

      if (slot !== null) {
        componentProps.slot = slot
      }

      return h(cmp.component, componentProps, children)
    },
    renderComponents(h, cmps, slot = null, rootStory = null) {
      let components = []

      if (Array.isArray(cmps)) {
        components = cmps
          .filter((cmp) => cmp !== undefined)
          .map((cmp) => this.renderComponent(h, cmp, slot, rootStory))
      } else if (cmps.content !== undefined) {
        components = this.renderComponents(h, [cmps.content], slot, rootStory)
      }

      return components.length > 0 ? components : h()
    },
  },
  render(h) {
    return this.renderComponents(h, [this.story.content], null, this.story)
  },
}
